<svelte:head>
	<script>
	var _iub = _iub || [];
	_iub.csConfiguration = {"siteId":3855070,"cookiePolicyId":16696840,"lang":"en","storage":{"useSiteId":true}};
	</script>
	<script type="text/javascript" src="https://cs.iubenda.com/autoblocking/3855070.js"></script>
	<script type="text/javascript" src="//cdn.iubenda.com/cs/gpp/stub.js"></script>
	<script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>
</svelte:head>



<script>
	import Header from './Header.svelte';
	import './styles.css';
	import LoadingOverlay from './LoadingOverlay.svelte';
	
	const d = new Date();
	let year = d.getFullYear();
</script>

<div class="app">
	<Header />
	<LoadingOverlay />
	<main>
		<slot />
	</main>

	<footer class="footer">
		<p>©2022-{year} by Impalance | <a href="https://impalance.co.uk/contact">Support</a>	| <a href="https://impalance.co.uk/cookiepolicy">Cookie Policy</a> | <a href="https://impalance.co.uk/privacypolicy">Privacy Policy</a></p>
	</footer>
</div>

<style>
	.footer {
		text-align: center;
	}
	.app {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}

	main {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 12px;
	}
	
	@media (min-width: 480px) {
		footer {
			padding: 12px 0;
		}
	}
</style>
